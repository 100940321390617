// import { useEffect, useState } from 'react';
// import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// // @mui
// import { styled } from '@mui/material/styles';
// import { Box, Button, Typography } from '@mui/material';
// import useAuth from '../../hooks/useAuth';
// // hooks
// import useSettings from '../../hooks/useSettings';
// import useResponsive from '../../hooks/useResponsive';
// import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// // config
// import { HEADER, NAVBAR } from '../../config';
// //
// import DashboardHeader from './header';
// import NavbarVertical from './navbar/NavbarVertical';
// import NavbarHorizontal from './navbar/NavbarHorizontal';
// import { DialogAnimate } from '../../components/animate';
// import { PATH_DASHBOARD } from '../../routes/paths';

// // ----------------------------------------------------------------------

// const MainStyle = styled('main', {
//   shouldForwardProp: (prop) => prop !== 'collapseClick',
// })(({ collapseClick, theme }) => ({
//   flexGrow: 1,
//   paddingTop: HEADER.MOBILE_HEIGHT + 24,
//   paddingBottom: HEADER.MOBILE_HEIGHT + 24,
//   [theme.breakpoints.up('lg')]: {
//     paddingLeft: 16,
//     paddingRight: 16,
//     paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
//     paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
//     width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
//     transition: theme.transitions.create('margin-left', {
//       duration: theme.transitions.duration.shorter,
//     }),
//     ...(collapseClick && {
//       marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
//     }),
//   },
// }));

// // ----------------------------------------------------------------------

// export default function DashboardLayout() {
//   const navigate = useNavigate();
//   const { pathname } = useLocation();
//   // console.log(pathname)
//   const emailConfigPage = pathname.includes('ConfigEmail');
//   // console.log(emailConfigPage)
//   const { collapseClick, isCollapse } = useCollapseDrawer();
//   const [openModel, setOpenModel] = useState(false);
//   const { user } = useAuth();

//   console.log(user, 'user')

//   useEffect(() => {
//     if (!user?.isEmailConfigured && user?.roleId === 2) {
//       setOpenModel(true);
//     }
//     if (emailConfigPage) {
//       setOpenModel(false);
//     }
//   }, [user?.isEmailConfigured, emailConfigPage,user?.roleId]);

//   const handleClose = () => {
//     setOpenModel(false);
//   };

//   const { themeLayout } = useSettings();

//   const isDesktop = useResponsive('up', 'lg');

//   const [open, setOpen] = useState(false);

//   const verticalLayout = themeLayout === 'vertical';

//   if (verticalLayout) {
//     return (
//       <>
//         <DialogAnimate open={openModel} sx={{ p: 5 }}>
//           <Typography variant="h6" mb={2}>
//             Please Configure your Sendgrid Email Service!
//           </Typography>
//           <Button
//             variant="contained"
//             onClick={() => {
//               navigate(PATH_DASHBOARD.profile.ConfigEmail);
//               handleClose();
//             }}
//           >
//             Configure Email
//           </Button>
//         </DialogAnimate>
//         <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

//         {isDesktop ? (
//           <NavbarHorizontal />
//         ) : (
//           <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
//         )}
//         <Box
//           component="main"
//           sx={{
//             px: { lg: 2 },
//             pt: {
//               xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
//               lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
//             },
//             pb: {
//               xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
//               lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
//             },
//           }}
//         >
//           <Outlet />
//         </Box>
//       </>
//     );
//   }

//   return (
//     <Box
//       sx={{
//         display: { lg: 'flex' },
//         minHeight: { lg: 1 },
//       }}
//     >
//       <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

//       <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

//       <MainStyle collapseClick={collapseClick}>
//         <Outlet />
//       </MainStyle>
//     </Box>
//   );
// }

import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import useAuth from '../../hooks/useAuth';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import { DialogAnimate } from '../../components/animate';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const emailConfigPage = pathname.includes('ConfigEmail');
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [openModel, setOpenModel] = useState(false);
  const { user } = useAuth();
  const [isEmailConfigured, setIsEmailConfigured] = useState(
    localStorage.getItem('emailConfigured') === 'true' || user?.isEmailConfigured === 1
  );

  useEffect(() => {
    const isConfigured = localStorage.getItem('emailConfigured') === 'true' || user?.isEmailConfigured === 1;
    setIsEmailConfigured(isConfigured);

    if (!isConfigured && user?.roleId === 2 && !emailConfigPage) {
      setOpenModel(true);
    } else {
      setOpenModel(false);
    }
  }, [user?.isEmailConfigured, emailConfigPage, user?.roleId]);

  useEffect(() => {
    return () => {
      if (user?.isEmailConfigured === 1) {
        localStorage.setItem('emailConfigured', 'true');
      }
    };
  }, [user?.isEmailConfigured]);

  const handleClose = () => {
    setOpenModel(false);
  };

  const { themeLayout } = useSettings();
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(false);
  const verticalLayout = themeLayout === 'vertical';

  if (verticalLayout) {
    return (
      <>
        {openModel && !isEmailConfigured && (
          <DialogAnimate open={openModel} sx={{ p: 5 }}>
            <Typography variant="h6" mb={2}>
              Please Configure your Sendgrid Email Service!
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigate(PATH_DASHBOARD.profile.ConfigEmail);
                handleClose();
              }}
            >
              Configure Email
            </Button>
          </DialogAnimate>
        )}
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical is OpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
