/* eslint-disable prettier/prettier */
import axios from '../utils/axios';
// import axiosInstance from "src/utils/axios";
import { config } from './config';

const getPackageList = async () => {
  const data = await axios.get('/api/v1/user/list_package', config);
  return data.data;
};
const getPackageById = async (id) => {
  const data = await axios.get(`/api/v1/user/package_detail/${id}`, config);
  return data.data;
};
const getActivePackageList = async () => {
  const data = await axios.get('/api/v1/user/list_active_package', config);
  return data.data;
};

const getMultiplePackagesById = async (ids) => {
  const data = await axios.post('/api/v1/user/package_details', { ids }, config);
  return data.data;
};
const addPackage = async (body) => {
  const data = await axios.post('/api/v1/user/add_package', body, config);
  return data.data;
};
const updatePackage = async (body) => {
  const data = await axios.put('/api/v1/user/update_package', body, config);
  return data.data;
};
const deletePackage = async (id) => {
  const data = await axios.delete(`/api/v1/user/delete_package/${id}`, config);
  return data.data;
};

export const packageService = {
  getPackageList,
  getActivePackageList,
  addPackage,
  updatePackage,
  deletePackage,
  getPackageById,
  // getMultiplePackageById,
  getMultiplePackagesById,
};
